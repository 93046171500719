import AddIcon from '@mui/icons-material/Add';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { useMemo, useState } from 'react';
import DictionaryService from '../../../services/DictionaryService';
import Playground from '../PlaygroundDrawer/PlaygroundDrawer';
import './DictionaryBucket.scss';
import { DictionaryEntry } from './DictionaryEntry/DictionaryEntry';
import { useDictionaryContext } from './store/DictionaryContext';
import { DictionaryBucketEntry } from './store/DictionaryReducer';

const DictionaryBucket = () => {
  const { entries, addEntry, startSavingEntry, finishSavingEntry, resetAll } = useDictionaryContext();

  const [savedEntriesCount, setSavedEntriesCount] = useState(0);

  const handleSave = (entry: DictionaryBucketEntry) => {
    startSavingEntry(entry.id);

    const { original, type, adaption } = entry;

    return DictionaryService.createEntry({ original, type, adaption })
      .then(() => finishSavingEntry(entry.id))
      .then(() => true)
      .catch((error) => {
        finishSavingEntry(entry.id, error);
        return Promise.resolve(false);
      });
  };

  const finishedEntries = useMemo(() => {
    return entries.filter((e) => e.savable);
  }, [entries]);

  const handleSaveAll = async () => {
    let count = 0;
    // in order to ensure that not the fastest save call wins; we
    // call the saves one after another -> reduces errors while saving
    for (const entry of finishedEntries) {
      const success = await handleSave(entry);
      if (success) count++;
    }
    setSavedEntriesCount(count);
  };

  const addEmptyEntry = () => addEntry('', false);

  const handleResetAll = () => resetAll();

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={true}
      data-testid="DictionaryBucket"
      id="DictionaryBucket"
      className="dictionary-bucket"
    >
      <header data-testid="DictionaryBucket-headline">
        <h5>Dictionary Bucket</h5>
      </header>
      <main>
        <div className="dictionary-bucket--entries-wrapper">
          {entries &&
            [...entries]
              .reverse()
              .map((entry) => (
                <DictionaryEntry key={`dic-${entry.id}`} entry={entry} handleSave={() => handleSave(entry)} />
              ))}

          <Button
            startIcon={<AddIcon />}
            color="primary"
            aria-label="add"
            onClick={addEmptyEntry}
            data-testid="DictionaryBucket--addEmptyEntry"
            className="add-empty-dictionary-btn"
            disabled={entries.some((entry) => entry.original.trim() === '')}
          >
            Wort hinzufügen
          </Button>
        </div>

        {savedEntriesCount > 0 && (
          <Alert
            // className={classes.marginTopBottom}
            data-testid="DictionaryBucket--saveAll--success"
            onClose={() => {
              setSavedEntriesCount(0);
            }}
          >
            {savedEntriesCount} Einträge erfolgreich angelegt
          </Alert>
        )}

        <div className="dictionary-bucket--actions">
          <Button
            variant="outlined"
            color="primary"
            aria-label="reset all"
            disabled={entries.length === 1 && entries[0].original === ''}
            onClick={handleResetAll}
            data-testid="DictionaryBucket--resetAllEntry"
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="primary"
            aria-label="save all"
            disableElevation
            data-testid="DictionaryBucket--saveAll"
            onClick={handleSaveAll}
            disabled={finishedEntries.length === 0}
          >
            Alle Speichern
          </Button>
        </div>

        <Playground />
      </main>
    </Drawer>
  );
};

export default DictionaryBucket;
