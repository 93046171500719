import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual, isEqualWith } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Footer from '../../components/Footer/Footer';
import { withInitializedUser } from '../../components/InitializedUser/WithInitializedUser';
import { NotificationService } from '../../components/Notifications/Notifications';
import { ssmlTagsRemover } from '../../components/StrippedSSMLText/StrippedSSMLText';
import { scrollTop } from '../../helpers/Helpers';
import { useBrowserNotification } from '../../hooks';
import { useUpdateEffect } from '../../hooks/useUpdateEffect/useUpdateEffect';
import { ArticleStatus, BRAND, IArticle } from '../../models/Article';
import { FetchArticleListParams, FetchArticleListResponse, getArticleList } from '../../services/ArticleService';
import { IAuthState, useAuthContext } from '../../services/Auth/AuthContext';
import AlexaUpdateModal from './AlexaUpdateModal/AlexaUpdateModal';
import ArticleForm from './ArticleForm/ArticleForm';
import ArticleJobList from './ArticleJobList/ArticleJobList';
import './ArticleLandingPage.scss';
import ArticleListHeader from './ArticleListHeader/ArticleListHeader';
import { useArticlesContext, WithArticlesContext } from './store/ArticlesContext';

const UPDATE_INTERVAL = 5000;

const getArticles = ({ brand, exclusiveStartKey, cancelToken, category, status }: FetchArticleListParams) => {
  return getArticleList({
    brand,
    exclusiveStartKey,
    cancelToken,
    status,
    category,
  });
};

const getPagedArticles = (res: FetchArticleListResponse) => {
  const exclusiveStartKey = res.lastArticleId;
  const pageSize = res.pageSize;
  const articles = res.items;

  return {
    articles,
    exclusiveStartKey,
    pageSize,
  };
};

type PagedArticles = ReturnType<typeof getPagedArticles>;

const hasUpdatedArticlesEntries = (
  newPagedArticles: Array<PagedArticles>,
  pagedArticles: Array<PagedArticles>,
  pageIndex: number
) => {
  return !isEqualWith(newPagedArticles, pagedArticles, (objValue, othValue) => {
    if (newPagedArticles.length !== pagedArticles.length) {
      return false;
    }

    const removeDownloadLinkProp = (articles: IArticle[]) =>
      articles.map((article) => {
        const { ...rest } = article;
        return rest;
      });

    const objToCompare = removeDownloadLinkProp(objValue[pageIndex].articles);
    const otherToCompare = removeDownloadLinkProp(othValue[pageIndex].articles);

    return isEqual(objToCompare, otherToCompare);
  });
};

export const ArticleLandingPage = () => {
  const { brand } = useAuthContext() as { brand: BRAND; switchBrand: IAuthState['switchBrand'] };
  const {
    setArticles,
    setLoadingMore,
    selectedResort,
    selectedStatus,
    checkedArticleIds,
    setCheckedArticlesIds,
    resetArticlesFilter,
  } = useArticlesContext();
  const queryClient = useQueryClient();
  const { sendNotification } = useBrowserNotification();

  const previousArticles = useRef<IArticle[]>([]);
  const pollingTimeout = useRef<NodeJS.Timeout>();

  const [pagedArticles, setPagedArticles] = useState<Array<PagedArticles>>([]);

  const [isPolling, setIsPolling] = useState(false);
  const [isFetchingArticles, setIsFetchingArticles] = useState(true);
  const [hasNoArticles, setHasNoArticles] = useState(false);

  const updateArticlesFromResult = (res: FetchArticleListResponse, pageIndex: number) => {
    const currentPagedArticles = getPagedArticles(res);
    const newPagedArticles: Array<PagedArticles> = [...pagedArticles, currentPagedArticles];

    if (hasUpdatedArticlesEntries(newPagedArticles, pagedArticles, pageIndex)) {
      setPagedArticles(newPagedArticles);
    }
  };

  // Clicking on `Load More` Button will fetch the previous articles
  const onLoadMore = () => {
    setLoadingMore(true);
    setIsPolling(false);
    clearTimeout(pollingTimeout.current);

    const lastPageIndex = Math.max(pagedArticles.length - 1, 0);
    const lastPage = pagedArticles[lastPageIndex];

    if (typeof lastPage.exclusiveStartKey === 'undefined') {
      NotificationService.error('Nachladen fehlgeschlagen.');
    }

    getArticles({
      brand,
      exclusiveStartKey: lastPage.exclusiveStartKey,
      category: selectedResort,
      status: selectedStatus,
    })
      .then((res: FetchArticleListResponse) => updateArticlesFromResult(res, lastPageIndex + 1))
      .finally(() => setLoadingMore(false));
  };

  const flattenPagedArticles = (pages: Array<PagedArticles>): IArticle[] => {
    return (pages || []).reduce((acc: IArticle[], { articles }) => {
      return acc.concat(...articles);
    }, []);
  };

  const hasMore = !!pagedArticles[Math.max(pagedArticles.length - 1, 0)]?.exclusiveStartKey;

  const setFirstPage = (res: FetchArticleListResponse) => {
    const currentPagedArticles = getPagedArticles(res);

    if (currentPagedArticles.articles.length === 0) {
      setHasNoArticles(true);
    }

    setPagedArticles([currentPagedArticles]);
    previousArticles.current = flattenPagedArticles([currentPagedArticles]);
  };

  //* `useQuery` calls this function everytime the article list fetched successfully
  const updateFirstPage = (res: FetchArticleListResponse) => {
    const newPagedArticles = getPagedArticles(res);
    const newArticles = flattenPagedArticles([newPagedArticles]);
    notifySynthesizedArticles(newArticles);

    setPagedArticles([newPagedArticles]);
    previousArticles.current = flattenPagedArticles([newPagedArticles]);
  };

  const notifySynthesizedArticles = (newArticles: IArticle[]) => {
    const currentArticles = previousArticles.current;
    const synthesizedArticleList = newArticles.filter((article) => article.status === ArticleStatus.SYNTHESIZED);

    const articlesForNotification = synthesizedArticleList.filter((synthesizedArticle) => {
      if (!currentArticles.some((currentArticle) => currentArticle.id === synthesizedArticle.id)) {
        return true;
      }
      return currentArticles.some(
        (currentArticle) =>
          currentArticle.id === synthesizedArticle.id && currentArticle.status === ArticleStatus.RUNNING
      );
    });

    if (articlesForNotification.length > 0) {
      let count = 0;
      const notificationInterval = setInterval(() => {
        const currentArticleForNotification = articlesForNotification[count];
        sendNotification({
          title: currentArticleForNotification.brand,
          body: `${ssmlTagsRemover(currentArticleForNotification.title)}`,
          url: window.location.origin + `/${currentArticleForNotification.brand}/${currentArticleForNotification.id}`,
        });

        count = count + 1;

        if (count === articlesForNotification.length) {
          clearInterval(notificationInterval);
        }
      }, 500);
    }
  };

  const stopPollingForUpdate = () => {
    queryClient.cancelQueries({
      queryKey: ['fetchFirstPage'],
    });
    setIsPolling(false);
    clearTimeout(pollingTimeout.current);
  };

  // setting isPolling to true will immediately enable useQuery and call getArticles
  // so that 2 requests are not fired immediately one after the other, we have to wait and enable useQuery after UPDATE_INTERVAL
  const setPollingTimeout = () =>
    setTimeout(() => {
      setIsPolling(true);
    }, UPDATE_INTERVAL);

  useQuery({
    queryKey: ['fetchFirstPage'],

    queryFn: () =>
      getArticleList({
        brand,
        category: selectedResort,
        status: selectedStatus,
      })
        .then((res) => {
          updateFirstPage(res);
          return res;
        })
        .catch((error: string) => {
          setIsPolling(false);
          NotificationService.error(error);
        }),

    refetchInterval: UPDATE_INTERVAL,
    refetchIntervalInBackground: true,
    retry: false,
    enabled: isPolling,
  });

  const initialFetch = () => {
    setIsPolling(false);

    trackPromise(
      getArticles({
        brand,
        category: selectedResort,
        status: selectedStatus,
      })
    )
      .then((res) => {
        setFirstPage(res);
      })
      .catch((error: string) => {
        NotificationService.error(error);

        setIsPolling(false);
        setIsFetchingArticles(false);
      })
      .finally(() => {
        setIsFetchingArticles(false);
        pollingTimeout.current = setPollingTimeout();
      });
  };

  useUpdateEffect(() => {
    initialFetch();
  }, [selectedResort!, selectedStatus!]);

  useEffect(() => {
    const newArticles = flattenPagedArticles(pagedArticles);
    setArticles(newArticles);
  }, [pagedArticles]);

  useEffect(() => {
    if (!brand) {
      console.log('Brand not found');
      return;
    }
    //On changing brand we are scrolling the page to top of the list
    scrollTop();

    if (selectedResort || selectedStatus) {
      resetArticlesFilter();
      return;
    }

    if (checkedArticleIds.length) {
      setCheckedArticlesIds([]);
    }

    initialFetch();
  }, [brand]);

  return (
    <div className="articleCreation-wrapper" data-testid="articleCreation-wrapper">
      <ArticleForm brand={brand} onSubmitSuccess={() => initialFetch()} />
      <AlexaUpdateModal onPublishSuccess={() => initialFetch()} />
      <ArticleListHeader stopPollingForUpdate={stopPollingForUpdate} onClearSearch={() => initialFetch()} />
      <ArticleJobList
        hasMore={hasMore}
        onLoadMore={onLoadMore}
        onListChanged={() => initialFetch()}
        stopPollingForUpdate={stopPollingForUpdate}
        scrollTop={() => scrollTop()}
        fetchingArticles={isFetchingArticles}
        hasNoArticles={hasNoArticles}
      />
      <Footer />
    </div>
  );
};

export default withInitializedUser(WithArticlesContext(ArticleLandingPage));
